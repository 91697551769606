import React from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"

export default ({ img, imgLabel, header, text }) => (
  <Col xs={12} sm={8} md={6}>
    <Col xs={12} className={style.featureHeader}>
      <Row around="xs" className={style.card}>
        <Col xs={12} md={12} lg={4}>
          <div className={style.icon}>
            <img src={img} alt={imgLabel} />
          </div>
        </Col>
        <Col xs={12} md={12} lg={7}>
          <div className={style.text}>
            <span className={style.headerCard}>{header}</span>
            <p>{text}</p>
          </div>
        </Col>
      </Row>
    </Col>
  </Col>
)
