import React from "react"
import style from "./Hero.module.scss"
import { Row, Col } from "react-flexbox-grid"
import FeatureDetail from "./FeatureDetail"

export default () => (
  <section className={style.featureSection}>
    <div className={style.featureHeaderImg}></div>
    <div className={style.container}>
      <Row center="xs">
        <Col xs={12} className={style.featureTitle}>
          <h1>Features</h1>
          <h2>Some of UpdateHub main features</h2>
        </Col>
        <FeatureDetail
          img="/imgs/features/Atomic.png"
          imgLabel="feature atomic"
          header="Atomic Updates"
          text="A failure occurring during update package installation process must not brick your device or let it inconsistent state."
        />
        <FeatureDetail
          img="/imgs/features/SecureTLS.png"
          imgLabel="feature secure"
          header="Secure TLS communication"
          text="All network communication with UpdateHub is encrypted to ensure the confidentiality of all data sent and received."
        />
      </Row>
      <Row center="xs">
        <FeatureDetail
          img="/imgs/features/Integrity.png"
          imgLabel="feature integrity"
          header="Integrity checksum"
          text="Update package integrity is always verified on device to avoid corruption during file transfer over a network."
        />
        <FeatureDetail
          img="/imgs/features/PackageSigning.png"
          imgLabel="feature package"
          header="Package signing"
          text="Update package authenticity is validated using cryptographic signatures before it is installed on device."
        />
      </Row>
    </div>
  </section>
)
