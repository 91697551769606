import React from "react"
import styles from "./Footer.module.scss"
import { Row, Col } from "react-flexbox-grid"

export default () => (
  <div className={styles.contactUs}>
    <div className={styles.container}>
      <div className={styles.poweredByContainer}>
        <Row center="xs" start="md">
          <Col xs={12} md={4}>
            <a
              href="https://ossystems.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/imgs/footer-logo-ossystems.png"
                width="120px"
                alt="O.S. Systems logo"
              />
            </a>
          </Col>
          <Col xs={12} md={4}>
            <span className={styles.icons}>
              <a
                href="https://www.linkedin.com/company/updatehub"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/imgs/icon-linkedin.svg" alt="linkedin logo" />
              </a>
              <a
                href="https://github.com/UpdateHub"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/imgs/icon-gitter.svg" alt="gitter logo" />
              </a>
              <a
                href="https://feedly.com/i/subscription/feed/https://blog.updatehub.io/rss/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/imgs/icon-rss.svg" alt="rss logo" />
              </a>
              <a
                href="https://twitter.com/OSSystemsNews"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/imgs/icon-twitter.png" alt="twitter logo" />
              </a>
            </span>
          </Col>
          <Col xs={12} md={4} className="end-md">
            <span>Copyright © 2019 O.S. Systems</span>
          </Col>
        </Row>
      </div>
    </div>
  </div>
)
