import React from "react"
import styles from "./Buttons.module.scss"

const OutlineButton = ({ text, link }) =>
    <a
        className={`${styles.button} ${styles.buttonOutline} ${styles.buttonLg} ${styles.fullWidth}`}
        href={link}
    >
        {text}
    </a>

export default OutlineButton
