import React from "react"
import "./Header.scss"

export default ({ link, label, target }) => (
  <li className="luxbar-item">
    <a href={link} target={target} rel="noopener noreferrer">
      {label}
    </a>
  </li>
)
