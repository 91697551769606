import React from "react"
import Layout from "../components/Layout.js"
import Hero from "../components/features/Hero.js"
import Footer from "../components/index/Footer.js"
import FooterContact from "../components/index/FooterContact.js"

export default () => (
  <Layout>
    <Hero />
    <FooterContact />
    <Footer />
  </Layout>
)
