import React from "react"
import styles from "./Buttons.module.scss"

const NavButton = ({ text, link }) =>
    <a
        className={`${styles.button} ${styles.buttonNav}`}
        href={link}
    >
        {text}
    </a>

export default NavButton
