import React, { useState, useEffect } from "react"
import "./Header.scss"
import ItemsHeader from "./ItemsHeader"

const Header = () => {
  const [fixedOnTop, setFixedOnTop] = useState(true)

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll)
    handleOnScroll()
  })

  const handleOnScroll = () => setFixedOnTop(window.pageYOffset < 30)

  return (
    <header
      className={`luxbar luxbar-fixed ${
        fixedOnTop ? `luxbar-fixed-on-top` : ""
      }`}
    >
      <input type="checkbox" id="luxbar-checkbox" className="luxbar-checkbox" />
      <div className="luxbar-menu luxbar-menu-right luxbar-menu-uh">
        <ItemsHeader />
      </div>
    </header>
  )
}

export default Header
