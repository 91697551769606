import React from "react"
import "./Header.scss"
import NavButton from "../Buttons/NavButton"
import Item from "./Item"

const ItemsHeader = () => (
  <div className="uh-container">
    <ul className="luxbar-navigation">
      <li className="luxbar-header">
        <a href="/#main">
          <img src="/imgs/updatehub-logo.png" alt="updateHUB logo" />
        </a>
        <label
          className="luxbar-hamburger luxbar-hamburger-doublespin"
          htmlFor="luxbar-checkbox"
        >
          <span />
        </label>
      </li>
      <Item link="/features/#features" label="Features" />
      <Item link="/pricing/#pricing" label="Pricing" />
      <li className="luxbar-item dropdown">
        <a href="/#">
          resources
          <img src="/imgs/Dropdown.svg" alt="dropdown" />
        </a>
        <ul>
          <Item link="/cases/#cases" label="Cases" />
          <Item link="https://docs.updatehub.io" label="Docs" target="_blank" />
          <Item
            link="https://github.com/UpdateHub/updatehub-ce"
            label="Community Edition"
            target="_blank"
          />
          <Item
            link="https://github.com/UpdateHub"
            label="Projects"
            target="_blank"
          />
          <Item link="https://blog.updatehub.io" label="Blog" target="_blank" />
        </ul>
      </li>
      <li className="luxbar-item dropdown">
        <a href="/#">
          Contact
          <img src="/imgs/Dropdown.svg" alt="dropdown" />
        </a>
        <ul>
          <Item link="https://gitter.im/UpdateHub/community" label="Gitter" target="_blank" />
          <Item
            link="https://www.linkedin.com/company/updatehub"
            label="Linkedin"
            target="_blank"
          />
          <Item link="/contact/#contact" label="Get in touch" />
        </ul>
      </li>
      <Item link="https://dashboard.updatehub.io/" label="Log In" />
      <li>
        <NavButton text={"Get started free"} link="https://auth.updatehub.io/auth/signup" />
      </li>
    </ul>
  </div>
)

export default ItemsHeader
