import React from "react"
import styles from "./FooterContact.module.scss"
import { Row, Col } from "react-flexbox-grid"
import OutlineButton from "../Buttons/OutlineButton"

export default () => (
  <div className={styles.contactUs}>
    <div className={styles.container}>
      <Row center="xs" middle="xs">
        <Col xs={12} sm={10} md={6}>
          <h4>Further information? Let’s talk and share ideas.</h4>
        </Col>
      </Row>
      <Row center="xs" middle="xs">
        <Col xs={12} sm={10} md={4}>
          <OutlineButton text={"Contact Us"} link={"/contact/#contact"} />
        </Col>
      </Row>
    </div>
  </div>
)
